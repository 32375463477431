import React from 'react'
import PropTypes from 'prop-types'

import ProgressiveImageStyled from './styled'

class ProgressiveImage extends React.PureComponent {
  state = {
    isLoading: true,
    currentImgSrc: this.props.placeHolderImgSrc,
  }

  image = null

  componentDidMount() {
    this.loadImage(this.props.src)
  }

  componentWillUnmount() {
    this.image.src = ''
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.handleImageChanged()
    }
  }

  handleImageChanged() {
    this.setState({
      currentImgSrc: this.props.placeHolderImgSrc,
      isLoading: true,
    })
    this.loadImage(this.props.src)
  }

  loadImage(imgSrc) {
    this.image = new Image()
    this.image.onload = () => {
      this.setState({
        currentImgSrc: this.image.src,
        isLoading: false,
      })
    }
    this.image.src = imgSrc
  }

  render() {
    let { className } = this.props
    className += this.state.isLoading ? ' loading' : ''
    return (
      <ProgressiveImageStyled
        id={this.props.id}
        data-test={this.props.id}
        className={className}
        style={this.props.style}
        src={this.state.currentImgSrc}
      />
    )
  }
}

ProgressiveImage.defaultProps = {
  id: 'progressive-img',
  style: {},
  className: '',
  src: '',
  placeHolderImgSrc: '',
}

ProgressiveImage.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  src: PropTypes.string,
  placeHolderImgSrc: PropTypes.string,
  style: PropTypes.object,
}

export default ProgressiveImage
